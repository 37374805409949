import React, { useState } from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol,MDBContainer, MDBRow, MDBListGroup, MDBListGroupItem, MDBCardHeader, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import banner from "../../assets/img/banner.png"
import costIcon from "../../assets/img/costIcon.png"

export default function TransactionFailed() {


 return (
    <div className="wrapper">
		<MDBContainer className='m-0 p-0 mt-lg-2' style={{ height: '100vh' }} fluid>
		  <MDBRow>
		  <MDBCol  sm="12" lg="6">
		    	<img src={banner} style={{ width: '100%' }} />
		    </MDBCol>
		    <MDBCol className="mt-4 mt-lg-0" sm="12" lg="6" >
				<h2 className='ml-0  pl-0' style={{ fontWeight: 600, color: "#353840" }}>The Black Mamba</h2>
	
				<MDBCard className="mt-4 ml-2" border="light" >
			      <MDBCardHeader  className="pt-4 pb-4" style={{fontWeight:700,backgroundColor:"#fff",color:"#353840"}}>Description</MDBCardHeader>
			      <MDBCardBody style={{backgroundColor:"#FBFDFF"}}>
			        <MDBCardText style={{color:"#353840"}}>
			          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
			        </MDBCardText>
			      </MDBCardBody>
			    </MDBCard>
				<MDBRow className="pl-4 ">
						<MDBCol md="6" className="d-inline-flex p-0">
							<h4  className="" style={{ marginRight:'10px', paddingTop:'23px', }}><b style={{ fontWeight: 700 }}>Package</b></h4>
							<MDBDropdown className="ml-0 pl-0 pt-2 ">
									<MDBDropdownToggle caret color="white">
										1000
									</MDBDropdownToggle>
									<MDBDropdownMenu basic>
										<MDBDropdownItem>1000</MDBDropdownItem>
										<MDBDropdownItem>2000</MDBDropdownItem>
										<MDBDropdownItem>3000</MDBDropdownItem>
									</MDBDropdownMenu>
								</MDBDropdown>
						</MDBCol>							
						</MDBRow>
			  <MDBCard className="mt-2 ml-2" border="light" >
			      <MDBCardBody style={{backgroundColor:"#FBFDFF"}}>
			      <MDBCardTitle tag="h6" style={{color:"#999999",fontWeight:500}} >Current price</MDBCardTitle>
                  <MDBRow style={{ borderWidth: 1, borderColor: "#000" }} >
				  <MDBCol size="12" className="d-inline-flex" >
										<img src={costIcon} style={{ width: '45px', height: '44px', }}  />
										<h2 style={{ fontWeight: 800, lineHeight: '44px', marginBottom:'0', marginLeft:'10px', }}>0.015</h2>
										<h6 style={{ marginLeft: -60, color: "#8E929E", lineHeight: '44px', marginBottom:'0', marginLeft:'10px', }}>($38.39)</h6>
									</MDBCol>	
                  </MDBRow>
                  


					  <MDBRow>
					  <MDBCol md="6">
			               <Link to="/TransationSuccessful">
			               		<MDBBtn flat style={{ fontWeight: 800, width:'100%', backgroundColor: "#5F4C80", color:"#fff" }} >Buy Now</MDBBtn>
			               </Link>
					    </MDBCol>
					    <MDBCol md="12">
					     <h6 style={{ marginLeft:0, color: "#FE0606", paddingTop:'20px',}}  className="ml-0" >Transaction failed. Please try again. </h6>
					    </MDBCol>
					  </MDBRow>                     
			      
			      </MDBCardBody>
			    </MDBCard>
		    </MDBCol>
		  </MDBRow>
		</MDBContainer>
    </div>
    );
}

