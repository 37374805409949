


import { React, useEffect, useState } from 'react';
import { Link, Router, Redirect, withRouter } from 'react-router-dom';
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBInput } from 'mdbreact';
import Web3 from 'web3';



const Claim = (props) => {
    const [confirmationNumber, setConfirmationNumber] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [errorMessage, setErrorMessage] = useState(false)

    let web3;
    let toAddress;

    if (window.ethereum) {
        web3 = new Web3(window.ethereum);
    } else if (window.Web3) {
        web3 = new Web3(window.Web3.currentProvider);
    }

    web3.eth.getAccounts()
        .then(async (addr) => {
            toAddress =  addr[0]
        })


    const submit = async () => {
        if (confirmationNumber.length === 0 || emailAddress.length === 0) {
            return
        }
        console.log('Confirmation Number', confirmationNumber)
        console.log('Email Address', emailAddress)
        console.log('To Address', toAddress)

        const requestData = {
            confirmation: confirmationNumber,
            email: emailAddress,
            to: toAddress
        }

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData)
        }
        
        const res = await fetch('https://api.getyams.com/v1/nfts/nftTransfer', options)
        const data = await res.json()
        if (data.success) {
            setErrorMessage(false)
            props.history.push("/TransationSuccessfulPage")
        } else {
            setErrorMessage(true)
        }
    }

    return (
        <div className="wrapper">
            <MDBContainer className='m-0 p-0 mt-4' style={{ height: '100vh' }} fluid>
                <MDBRow style={{ textAlign: "center" }} className='mt-5 mb-5 d-flex justify-content-center'>
                    <MDBCol size="12" className='mt-5 mb-5 '  >
                        <h3 style={{ fontWeight: 400, color: "#40434B", textAlign: "center", margin: 'auto', }}>Please enter the following information so we can locate your NFT(s).</h3>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <h6>{errorMessage ? 'There was an issue with finding your order or transferring your NFT. Please contact Play The Crowd for further assistance.' : null }</h6>
                    <MDBCol size="6" className='mt-0 mb-5'>
                        <MDBInput 
                            type="text" 
                            id="confirmationNumber" 
                            placeHolder="Please enter your confirmation number" 
                            value={confirmationNumber}
                            onChange={(e) => setConfirmationNumber(e.target.value)}
                        ></MDBInput>
                    </MDBCol>
                    <MDBCol size="6" className='mt-0 mb-5'>
                        <MDBInput 
                            type="email" 
                            id="emailAddress" 
                            placeHolder="Please enter your email address" 
                            value={emailAddress}
                            onChange={(e) => setEmailAddress(e.target.value)}
                        ></MDBInput>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12" className='mt-2 mb-5'>
                        <Link to="/Checkout">
                            <MDBBtn className='pt-3 pb-3' flat style={{ fontWeight: 800, paddingLeft: 40, paddingRight: 40, backgroundColor: "#5F4C80", color: "#fff", fontSize: 16, float: "left" }} >Go Back</MDBBtn>
                        </Link>
                        <MDBBtn className='pt-3 pb-3' flat
                            style={{ fontWeight: 800, paddingLeft: 40, paddingRight: 40, backgroundColor: "#5F4C80", color: "#fff", fontSize: 16, float: "right" }}
                            onClick={() => submit()}>Submit</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default withRouter(Claim);