


import React from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol,MDBContainer, MDBRow, MDBListGroup, MDBListGroupItem, MDBCardHeader, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';

import MetaMaskImage from "../../assets/img/MetaMaskWallet.png";


export default function MetaMaskWallet() {
  return (
<div className="wrapper">
		<MDBContainer className='m-0 p-0 mt-4' style={{ height: '100vh' }} fluid>
			<MDBRow style={{textAlign:"center"}} className='mt-5 mb-5 d-flex justify-content-center'>	
				<MDBCol  size="12"  className='mt-5 mb-5 '  >
					<h3 style={{fontWeight:400,color:"#40434B",textAlign:"center", margin:'auto',}}>You need a MetaMask Wallet for this transaction.</h3>
				</MDBCol>
				<MDBCol  size="12"  className='mt-0 mb-5'>
					<img src={MetaMaskImage} />
				</MDBCol>
				<MDBCol  size="12"  className='mt-2 mb-5'>
				
				<Link to="/TransactionPending">
					<MDBBtn className='pt-3 pb-3' flat style={{ fontWeight: 800, paddingLeft: 40, paddingRight: 40, backgroundColor: "#5F4C80", color:"#fff",fontSize:16 }} >Get MetaMask</MDBBtn>
				</Link>

				</MDBCol>
			</MDBRow>
		</MDBContainer>
	</div>
  	);
}