import React, {useEffect} from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import './App.css';

import Checkout from './components/Checkout/Checkout';
import MetaMaskWallet from './components/Checkout/MetaMaskWallet';
import TransactionPending from './components/Checkout/TransactionPending';
import TransactionFailed from './components/Checkout/TransactionFailed';
import TransationSuccessful from './components/Checkout/TransationSuccessful';
import TransationSuccessfulPage from './components/Checkout/TransationSuccessfulPage';
import Claim from './components/Checkout/Claim'
import LogoWhite from './assets/img/sm-PTC-CompLogo-White.png'

import {
MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol
} from "mdbreact";

import Logo from "./assets/img/CheckoutPage.png"

function App() {
	useEffect(() => {
		document.title = "Claim NFTicketStub"
	  }, [])
  return (
    <div className="">
      <BrowserRouter>

		<MDBNavbar style={{backgroundColor:"#000"}} expand="md">
			<MDBNavbarBrand className="ml-4">
			   <Link to="/">
				 <img  src={LogoWhite} className="img-fluid" alt="" />
			   </Link>	 
			</MDBNavbarBrand>
		</MDBNavbar>

		<Switch>
			<Route path="/MetaMaskWallet"> 
				<MetaMaskWallet /> 
			</Route>
			<Route path="/TransactionPending"> 
				<TransactionPending  /> 
			</Route>
			<Route path="/TransactionFailed"> 
				<TransactionFailed  /> 
			</Route>
			<Route path="/TransationSuccessful"> 
				<TransationSuccessful  /> 
			</Route>	
			<Route path="/TransationSuccessfulPage"> 
				<TransationSuccessfulPage  /> 
			</Route>
			<Route path="/Claim"> 
				<Claim  /> 
			</Route>									
			<Route path="/">
				<Checkout />
			</Route>
        </Switch>

      </BrowserRouter>
    </div>
  );
}




export default App;