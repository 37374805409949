import React, { useState } from 'react';
import { BrowserRouter, Switch, Route, Link, Redirect } from 'react-router-dom';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol, MDBContainer, MDBRow, MDBListGroup, MDBListGroupItem, MDBCardHeader, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import banner from "../../assets/img/banner.png"
import costIcon from "../../assets/img/costIcon.png"
import Web3 from 'web3'
import chysBag from '../../assets/img/Chys_Bag.jpg'

export default function Checkout() {
	let web3;

	if (window.ethereum) {
		web3 = new Web3(window.ethereum);
	} else if (window.Web3) {
		web3 = new Web3(window.Web3.currentProvider);
	}

	if (web3) {
		web3.eth.getAccounts()
			.then(async (addr) => {
				if (addr.length === 0) {
					//If there are no accounts, it means web3 is connected but now they need to give permission
					console.log('Wallet found!')
					console.log('Asking user for permission...')

					window.ethereum.request({ method: 'eth_requestAccounts' })
					//This will pop up if we have the web3 connected, but don't have access to any accounts. According to documentation, we probably want this to run off a button.

				} else {
					//Accounts found and load page as normal.
					console.log('Accounts found!');
					console.log('Connecting to account ', addr[0])

					//Here we want to load the /Checkout page since web3 is connected and we have access to the Metamask accounts
				}
			})
		return (
			<div className="wrapper">
				<MDBContainer className='m-0 p-0 mt-lg-2' style={{ height: '100vh' }} fluid>
					<MDBRow>
						<MDBCol sm="12" lg="6"  >
							<img src={chysBag} style={{ width: '100%' }} />
						</MDBCol>
						<MDBCol className="mt-4 mt-lg-0" sm="12" lg="6">
							<h2 className='ml-0 pl-0' style={{ fontWeight: 600, color: "#353840" }}>Chy's Reality</h2>

							<MDBCard className="mt-4 ml-2" border="light" >
								<MDBCardHeader className="pt-4 pb-4" style={{ fontWeight: 700, backgroundColor: "#fff", color: "#353840" }}>Description</MDBCardHeader>
								<MDBCardBody style={{ backgroundColor: "#FBFDFF" }}>
									<MDBCardText style={{ color: "#353840" }}>
									Here you can claim your Chy buck Bag! Be sure to already have your MetaMask wallet available! You will need the email address and confirmation number from your order to claim your bag!
									</MDBCardText>
								</MDBCardBody>
							</MDBCard>
							{/* <MDBRow className="pl-4 ">
								<MDBCol md="6" className="d-inline-flex p-0">
									<h4 className="" style={{ marginRight: '10px', paddingTop: '23px', }}><b style={{ fontWeight: 700 }}>Package</b></h4>
									<MDBDropdown className="ml-0 pl-0 pt-2 ">
										<MDBDropdownToggle caret color="white">
											1000
										</MDBDropdownToggle>
										<MDBDropdownMenu basic>
											<MDBDropdownItem>1000</MDBDropdownItem>
											<MDBDropdownItem>2000</MDBDropdownItem>
											<MDBDropdownItem>3000</MDBDropdownItem>
										</MDBDropdownMenu>
									</MDBDropdown>
								</MDBCol>
							</MDBRow> */}
							<MDBCard className="mt-2 ml-2" border="light" >
								<MDBCardBody style={{ backgroundColor: "#FBFDFF" }}>
									{/* <MDBCardTitle tag="h6" style={{ color: "#999999", fontWeight: 500 }} >Current price</MDBCardTitle> */}
									{/* <MDBRow style={{ borderWidth: 1, borderColor: "#000" }} >
										<MDBCol size="12" className="d-inline-flex" >
											<img src={costIcon} style={{ width: '45px', height: '44px', }} />
											<h2 style={{ fontWeight: 800, lineHeight: '44px', marginBottom: '0', marginLeft: '10px', }}>0.015</h2>
											<h6 style={{ marginLeft: -60, color: "#8E929E", lineHeight: '44px', marginBottom: '0', marginLeft: '10px', }}>($38.39)</h6>
										</MDBCol>
									</MDBRow> */}
									<MDBRow>
										<MDBCol md="6">
											<Link to="/Claim"> 
												{/* <MDBBtn flat style={{ fontWeight: 800, width: '100%', backgroundColor: "#5F4C80", color: "#fff" }} >Buy Now</MDBBtn> */}
												<MDBBtn flat style={{ fontWeight: 800, width: '100%', backgroundColor: "#5F4C80", color: "#fff" }} >Claim Now</MDBBtn>
											</Link>
										</MDBCol>
									</MDBRow>

								</MDBCardBody>
							</MDBCard>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</div>
		);
	} else {
		console.log('Web3 extension and Metamask is not connected...');
		//Here we want to redirect to the /MetaMaskWallet because the user needs to install the web3 hooks/Metamask to their browser.

		return (
			<Redirect to="/MetaMaskWallet" />
		)
	}
}



