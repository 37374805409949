


import React from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol,MDBContainer, MDBRow, MDBListGroup, MDBListGroupItem, MDBCardHeader, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';

import banner1 from "../../assets/img/banner.png"
import banner2 from "../../assets/img/banner2.png"
import banner3 from "../../assets/img/banner3.png"


export default function TransationSuccessfulPage() {
  return (
<div className="wrapper">
		<MDBContainer className='m-0 p-0' style={{ height: '100vh' }} fluid>
			<h2 className='ml-0 pl-0' style={{ fontWeight: 600, color: "#353840" }}>Congratulations. Here is your NFT.</h2>
			<MDBRow className='mt-4 mb-5'>	
				<MDBCol  sm="12" md="6" lg="4" className='mt-0 mb-5'>
				    				
				      <MDBCard style={{ width: "100%" }}>
				        <MDBCardImage className="img-fluid" style={{ width: '100%' }} src={'https://www.nfticketstub.com/media/Chy/Chy-Money-Bucks-Bag.gif'} />
				        <MDBCardBody className="p-2 pl-3  m-0">
				          <MDBCardTitle className="pb-1 m-0">Chy's Reality</MDBCardTitle>
				          <MDBCardText className="pt-0 pb-3 m-0" style={{lineHeight:1.2,color:"#A8A8A8"}}>
				            Some quick example text to build on the card title and make
				            up the bulk of the card&apos;s content.
				          </MDBCardText>
				        </MDBCardBody>
				      </MDBCard>
				    				      
				</MDBCol>

			</MDBRow>
		</MDBContainer>
	</div>
  	);
}